'use strict';

const SELECTORS = {
    videos: '.video-component .video',
    playButton: '.video-play-btn',
    pauseButton: '.video-pause-btn',
    muteButton: '.video-mute-btn'
};

/**
 * Toggles the visibility of play and pause buttons based on the video's current state.
 *
 * @param {HTMLVideoElement} video - The video element to check the state of.
 * @param {HTMLElement} playBtn - The play button element to toggle visibility of.
 * @param {HTMLElement} pauseBtn - The pause button element to toggle visibility of.
 */
function toggleButtonVisibility(video, playBtn, pauseBtn) {
    if (video.paused) {
        playBtn.classList.remove('d-none');
        pauseBtn.classList.add('d-none');
    } else {
        playBtn.classList.add('d-none');
        pauseBtn.classList.remove('d-none');
    }
}

/**
 * Toggles the state of a video element between play and pause.
 *
 * @param {string} action - The action to perform on the video, either 'play' or 'pause'.
 * @param {HTMLVideoElement} video - The video element to toggle.
 * @param {HTMLElement} playBtn - The play button element associated with the video.
 * @param {HTMLElement} pauseBtn - The pause button element associated with the video.
 */
function toggleVideoState(action, video, playBtn, pauseBtn) {
    if (action === 'play') {
        video.play();
    } else if (action === 'pause') {
        video.pause();
    }

    toggleButtonVisibility(video, playBtn, pauseBtn);
}

/**
 * Toggles the mute state of a video element.
 *
 * @param {HTMLVideoElement} video - The video element to toggle mute state for.
 */
function toggleMute(video) {
    video.muted = !video.muted; // eslint-disable-line no-param-reassign
}

/**
 * Attaches event listeners to video elements for play, pause, and mute functionality.
 */
function videoEvents() {
    const videos = document.querySelectorAll(SELECTORS.videos);

    for (let i = 0; i < videos.length; i++) {
        const video = videos[i];
        const videoContainer = video.closest('.video-component');
        const playButton = videoContainer.querySelector(SELECTORS.playButton);
        const pauseButton = videoContainer.querySelector(SELECTORS.pauseButton);
        const muteButton = videoContainer.querySelector(SELECTORS.muteButton);

        playButton.addEventListener('click', (event) => {
            event.preventDefault();
            toggleVideoState('play', video, playButton, pauseButton);
        });

        pauseButton.addEventListener('click', (event) => {
            event.preventDefault();
            toggleVideoState('pause', video, playButton, pauseButton);
        });

        muteButton.addEventListener('click', (event) => {
            event.preventDefault();
            toggleMute(video);
        });
        video.addEventListener('ended', () => toggleVideoState('pause', video, playButton, pauseButton));
    }
}

module.exports = {
    init: videoEvents
};
