'use strict';

var lcp = {
    init: function () {
        lcp.handleDetails();
        lcp.handleAddToCart();
        lcp.handlePDPUpdate();
    },

    handleDetails: function () {
        $('body').on('click', '.show-lcp-details', function () {
            var link = $(this);
            var container = link.parents('.protection').first();
            var details = container.find('.lcp-details');
            details.toggleClass('d-none');
        });
    },

    handleAddToCart: function () {
        $('body').on('updateAddToCartFormData', function (e, form) {
            let lcpItemData = [];
            let $containers = $('.pdp-lcp');
            if (form.lcpItem) {
                lcpItemData = JSON.parse(form.lcpItem);
            }

            if (form.hkUUID) {
                $containers = $('.hk-set-lcp-item');
            }

            $containers.each(function () {
                var $container = $(this);

                if ($container.find('.protection').is(':checked')) {
                    var lcpItem = $container.find('input[name="lcpItemID"]').val();
                    var productContainer = $container.closest('.product-detail');
                    var lcpObject = {
                        lcpItem: lcpItem,
                        pid: productContainer.data('pid')
                    };

                    if (form.hkUUID) {
                        productContainer = $container.find('[data-pid]');
                        lcpObject = {
                            lcpItem: lcpItem,
                            pid: productContainer.data('pid'),
                            bandID: productContainer.data('band-id')
                        };
                    }

                    lcpItemData.push(lcpObject);
                }
            });

            form.lcpItem = JSON.stringify(lcpItemData);
        });
    },

    handlePDPUpdate: function () {
        $('body').on('product:afterAttributeSelect', function (e, resp) {
            if (resp.data && resp.data.product) {
                var p = resp.data.product;
                var container = resp.container;
                if (container && container.length > 0) {
                    if (p.lcpItem && p.lcpItem.html) {
                        container.find('.pdp-lcp-container').html(p.lcpItem.html);
                    } else {
                        container.find('.pdp-lcp-container').html('');
                    }
                }
            }
        });
    }
};

module.exports = lcp;
