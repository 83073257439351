'use strict';

var base = require('./stickyHeader');

// Function to initialize sticky filter behavior
/**
 * Initializes the sticky filter behavior for the page.
 */
function initStickyHeader() {
    /**
     * Get scroll position and direction for Mobile Nav display
     */
    var lastScrollTop = 0; // Stores the last scroll position
    var scrollMaxCount = 0; // Counter to track scroll events
    var scrollDirection = ''; // Stores the current scroll direction
    var stickyHeader = $('.header-global');
    var stickyFilterPLPAnchor = $('.sticky-filter-init-anchor');

    // Function to handle scroll events
    /**
     * Handles the scroll events to manage the sticky header and filter behavior.
     */
    function handleScroll() {
        var scroll = window.pageYOffset; // Get the current scroll position
        var windowWidth = window.innerWidth; // Get the current window width
        // var isChromium = !!window.chrome; // Check if the browser is Chromium

        if (windowWidth < 1024) {
            scrollDirection = scroll < lastScrollTop ? 'up' : 'down';
            // Check if the window width is less than 1024px
            if (stickyFilterPLPAnchor.length > 0) {
                if (scroll > stickyFilterPLPAnchor.offset().top) {
                    $('.sticky-filter-container').addClass('sticky-filter-stuck');
                } else { $('.sticky-filter-container').removeClass('sticky-filter-stuck'); }
            }

            if (scroll >= 900 && scrollDirection === 'down') {
                // Set scrollMaxCount to 1 if scrolled down past 900px
                scrollMaxCount = 1;
            } else if (scroll <= 0 && scrollDirection === 'up') {
                // Reset scrollMaxCount to 0 if scrolled to the top
                scrollMaxCount = 0;
                stickyHeader.removeClass('header-global-mobFixedHide header-global-mobFixed');
            }

            var scrollDiff = Math.abs(scroll - lastScrollTop); // Calculate the scroll position difference
            lastScrollTop = scroll;

            if (scrollDirection === 'up' && scrollDiff > 15 && scrollMaxCount > 0) {
                // Show the fixed header if scrolling up and scrollMaxCount is greater than 0
                stickyHeader.addClass('header-global-mobFixed').removeClass('header-global-mobFixedHide');
            } else if (scrollDirection === 'down' && scrollMaxCount > 0) {
                // Hide the fixed header if scrolling down and scrollMaxCount is greater than 0
                stickyHeader.addClass('header-global-mobFixedHide');
            }
        } else {
            // Remove fixed header classes if window width is 1024px or greater
            stickyHeader.removeClass('header-global-mobFixedHide header-global-mobFixed');
        }
    }

    // Use native JavaScript to add the scroll event listener
    window.addEventListener('scroll', handleScroll);
}

// Export the initStickystickyHeader function
module.exports = {
    initStickyHeader: initStickyHeader,
    base: base
};
