var debounce = require('lodash/debounce');

/**
 * update root css variable `--scrollbar` with correct value
 */
function calculateScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);
    document.body.setAttribute('style', `--scrollbar: ${scrollbarWidth}px`);
}

module.exports = {
    setScrollBarWidth: () => {
        calculateScrollbarWidth();
        window.addEventListener(
            'resize',
            debounce(() => {
                calculateScrollbarWidth();
            }, 500)
        );
    }
};
