'use strict';
/**
 * Matches the start of the data text with the term provided in params.
 *
 * @param {Object} params - The parameters containing the term to match.
 * @param {Object} data - The data object containing the text to match against.
 * @returns {Object|null} The data object if there is a match, otherwise null.
 */
function matchStart(params, data) {
    params.term = params.term || '';
    if (data.text.trim().toUpperCase().indexOf(params.term.toUpperCase()) === 0) {
        return data;
    }
    return null;
}
/**
 * Initializes select2 fields on the page.
 *
 * This function applies select2 to all select elements on the page,
 * and configures each select element with a placeholder if available.
 *
 */
function initSelect2Field() {
    // Select all select elements except those with the .js-add-item-dropdown and .shippingState class
    $('select:not(.js-add-item-dropdown, .shippingState)').each(function () {
        const select2Config = {
            minimumResultsForSearch: -1
        };

        // Get placeholder from data attribute if available
        const placeholder = $(this).data('placeholder');

        // If a placeholder is set in the data attribute, add it to the config
        if (placeholder) {
            select2Config.placeholder = placeholder;
        }

        // Initialize select2 with the configuration
        $(this).select2(select2Config);
    });

    // Select all select elements with the .shippingState class
    $('select.shippingState, select.billingState').each(function () {
        const select2Config = {
            minimumResultsForSearch: 1,
            matcher: function (params, data) {
                return matchStart(params, data);
            }
        };

        // Get placeholder from data attribute if available
        const placeholder = $(this).data('placeholder');

        // If a placeholder is set in the data attribute, add it to the config
        if (placeholder) {
            select2Config.placeholder = placeholder;
        }

        // Initialize select2 with the configuration
        $(this).select2(select2Config);
    });
    // Select all select elements with the .shippingState class
    $('.result-sort-filter select.custom-select').each(function () {
        const select2Config = {
            minimumResultsForSearch: -1,
            width: 'style'
        };

        // Get placeholder from data attribute if available
        const placeholder = $(this).data('placeholder');

        // If a placeholder is set in the data attribute, add it to the config
        if (placeholder) {
            select2Config.placeholder = placeholder;
        }

        // Initialize select2 with the configuration
        $(this).select2(select2Config);
    });
}

/**
 * Globally overrides the `selectedIndex` property for all `<select>` elements.
 * this is required because PCA address autofill updates `selectedIndex` without triggering 'change' event
 *
 * This override ensures that when `selectedIndex` is changed programmatically:
 * - The `value` property of the `<select>` is updated accordingly.
 * - A `change` event is triggered on the `<select>` element.
 * @returns {void}
 */
function extendSelectIndexProperty() {
    const originalSelectedIndexDescriptor = Object.getOwnPropertyDescriptor(
        HTMLSelectElement.prototype,
        'selectedIndex'
    );

    // Redefine selectedIndex globally for all <select> elements
    Object.defineProperty(HTMLSelectElement.prototype, 'selectedIndex', {
        get: originalSelectedIndexDescriptor.get,
        set(newIndex) {
            const currentIndex = this.selectedIndex;

            if (currentIndex !== newIndex && this.options[newIndex]) {
                // Call the original setter to update the index
                originalSelectedIndexDescriptor.set.call(this, newIndex);

                // Update the value of the select and trigger the 'change' event
                const newValue = this.options[newIndex].value;
                if (newValue !== undefined) {
                    this.value = newValue;

                    // Create and dispatch the 'change' event
                    const changeEvent = new Event('change', { bubbles: true });
                    this.dispatchEvent(changeEvent);
                }
            }
        }
    });
}

module.exports = function () {
    extendSelectIndexProperty();
    initSelect2Field();

    $('body').on('selectFieldReinit', function () {
        initSelect2Field();
    });
};
