'use strict';

var formValidation = require('org/components/formValidation');
var createErrorNotification = require('base/components/errorNotification');
const device = require('../utils/device');
var focusHelper = require('base/components/focus');

/**
 * Submits the login form to the Account-Login route
 * @param {JQuery} $form - the login form as a jQuery object
 * @param {Object} formData - the form data to submit
 * @param {string} url - the Account-Login route's url
 */
function login($form, formData, url) {
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: formData,
        success: function (data) {
            $form.spinner().stop();
            if (!data.success) {
                formValidation($form, data);
                $form.trigger('login:error', data);
                $('.js-submit-login').removeAttr('disabled');
            } else if (data.forcePasswordReset) {
                if (device.desktop()) {
                    $('body').find('.js-forgot-password-link-desktop').trigger('click');
                } else {
                    let forgotPasswordLink = $('body').find('.js-forgot-password-link-mobile').attr('href');
                    forgotPasswordLink += (forgotPasswordLink.indexOf('?') !== -1 ? '&' : '?') + 'forcePasswordReset=forced';
                    window.location.href = forgotPasswordLink;
                }
                $('.js-submit-login').removeAttr('disabled');
            } else {
                if (data.encodedEmail) {
                    window.heap.identify(data.encodedEmail);
                }
                $form.trigger('login:success', data);

                if ($form.is('.js-login-modal-form')) {
                    // TODO: Move this to correct location for datalayer events
                    window.dataLayer.push({
                        event: 'account_login',
                        customer: {
                            id: data.customerNo,
                            group: '',
                            logged_in: true
                        }
                    });
                    location.reload();
                } else {
                    location.href = data.redirectUrl;
                }
            }
        },
        error: function (data) {
            if (data.responseJSON.redirectUrl) {
                window.location.href = data.responseJSON.redirectUrl;
            } else {
                if (data.responseJSON.message) {
                    createErrorNotification($('.error-messaging'), data.responseJSON.message);
                }
                $form.trigger('login:error', data);
                $form.spinner().stop();
                $('.js-submit-login').removeAttr('disabled');
            }
        }
    });
}

/**
 * Submits the registration form to the Account-SubmitRegistration route
 * @param {JQuery} $form - the registration form as a jQuery object
 * @param {Object} formData - the form data to submit
 * @param {string} url - the Account-SubmitRegistration route's url
 */
function register($form, formData, url) {
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: formData,
        success: function (data) {
            $form.spinner().stop();
            if (!data.success) {
                formValidation($form, data);
                $form.trigger('login:register:error', data);
            } else {
                if (data.encodedEmail) {
                    window.heap.identify(data.encodedEmail);
                }
                $form.trigger('login:register:success', data);

                if ($form.is('.js-signup-modal-form')) {
                    location.reload();
                } else {
                    location.href = data.redirectUrl;
                }
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification($('.error-messaging'), err.responseJSON.message);
            }

            $form.spinner().stop();
        }
    });
}

module.exports = {
    login: function () {
        $('body').on('submit', 'form.login, .js-login-modal-form', function (e) {
            e.preventDefault();
            var $form = $(this);
            var formData = $form.serialize();
            var url = $form.attr('action');

            $form.spinner().start();
            $form.trigger('login:submit', e);

            var $recaptcha = $form.find('[name="g-recaptcha-response"]');
            if ($recaptcha.length && 'grecaptcha' in window) {
                var siteKey = $recaptcha.data('sitekey');
                var action = $form.find('[name="g-recaptcha-action"]').val();
                window.grecaptcha.execute(siteKey, { action: action }).then(function (token) {
                    $recaptcha.val(token);
                    login($form, $form.serialize(), url);
                });
            } else {
                login($form, formData, url);
            }
        });
    },

    register: function () {
        $('body').on('submit', 'form.registration, .js-signup-modal-form', function (e) {
            e.preventDefault();
            var $form = $(this);
            var formData = $form.serialize();
            var url = $form.attr('action');

            $form.spinner().start();
            $form.trigger('login:register', e);

            var $recaptcha = $form.find('[name="g-recaptcha-response"]');
            if ($recaptcha.length && 'grecaptcha' in window) {
                var siteKey = $recaptcha.data('sitekey');
                var action = $form.find('[name="g-recaptcha-action"]').val();
                window.grecaptcha.execute(siteKey, { action: action }).then(function (token) {
                    $recaptcha.val(token);
                    register($form, $form.serialize(), url);
                });
            } else {
                register($form, formData, url);
            }
        });
    },

    resetPassword: function () {
        $('body').on('submit', '.reset-password-form', function (e) {
            var form = $(this);
            var formData = form.serialize();
            e.preventDefault();
            var url = form.attr('action');

            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: formData,
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');
                        if (!data.mobile) {
                            $('#submitEmailButton').text(data.buttonText)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $('.send-email-btn').empty()
                                .html('<a href="'
                                    + data.returnUrl
                                    + '" class="btn btn-primary btn-block">'
                                    + data.buttonText + '</a>'
                                );
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },

    trapFocusInForgotPasswordModal: function () {
        $('body').on('shown.bs.modal', '#requestPasswordResetModal', function () {
            var $this = $(this);
            $this.siblings().attr('aria-hidden', 'true');
            $this.find('.close').focus();
        });

        $('body').on('keydown', '#requestPasswordResetModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#requestPasswordResetModal',
                firstElementSelector: '.close',
                lastElementSelector: '#submitEmailButton',
                nextToLastElementSelector: '#reset-password-email'
            };

            focusHelper.setTabNextFocus(focusParams);
        });
    }
};
