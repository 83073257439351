'use strict';

var processInclude = require('base/util');

module.exports = () => {
    processInclude(require('./login/login'));
    processInclude(require('org/components/loginSignupModals'));

    // HELZ-608
    $('#add-to-email-list-label').on('click', function () {
        $(this).parent().toggleClass('force-check');
    });
};
