'use strict';
var clientSideValidation = require('../components/clientSideValidation');

module.exports = function () {
    const $loginModal = $('.js-login-modal');
    const $loginForm = $loginModal.find('.js-login-modal-form');
    const $openSignupModal = $loginModal.find('.js-open-signup-modal');
    const $submitLoginBtn = $loginModal.find('.js-submit-login');
    const $signupModal = $('.js-signup-modal');
    const $signupForm = $signupModal.find('.js-signup-modal-form');
    const $submitSignupBtn = $signupModal.find('.js-submit-signup');
    const $openLoginModal = $signupModal.find('.js-open-login-modal');
    const $forgotPasswordLink = $('.js-forgot-password-link');
    const $resetPasswordModal = $('#requestPasswordResetModal');

    if (!$loginModal.length || !$signupModal.length) return;

    if ($('.love-and-marriage-studio').length && $resetPasswordModal.length) {
        $resetPasswordModal.find('.modal-dialog').addClass('modal-dialog-centered');
    }

    $loginModal.add($signupModal).on('show.bs.modal', function () {
        var $modal = $(this);
        $modal.find('[name="csrf_token"]').each(function () {
            var $input = $(this);
            var updateUrl = $input.data('updateUrl');

            if (updateUrl) {
                $.ajax({
                    url: updateUrl,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        if (data.token) {
                            $input.val(data.token);
                        }
                    }
                });
            }
        });
    }).on('hidden.bs.modal', function () {
        var $form = $(this).find('form');
        clientSideValidation.functions.clearForm($form);
        $form.trigger('reset').find('.alert').remove();
    });

    $openSignupModal.on('click', function () {
        $loginModal.modal('hide');
        $signupModal.modal('show');
    });

    $openLoginModal.on('click', function () {
        $signupModal.modal('hide');
        $loginModal.modal('show');
    });

    $forgotPasswordLink.on('click', function (e) {
        e.preventDefault();
        $loginModal.modal('hide');
        $resetPasswordModal.modal('show');
    });

    $submitLoginBtn.on('click', function (e) {
        e.preventDefault();
        if (!$('.js-submit-login').attr('disabled')) {
            $loginForm.trigger('submit');
            $('.js-submit-login').attr('disabled', true);
        }
    });

    $submitSignupBtn.on('click', function (e) {
        e.preventDefault();
        $signupForm.trigger('submit');
    });

    // Enter event for login modal
    $loginModal.on('keydown', function (e) {
        if ($loginModal.hasClass('show') && e.keyCode === 13) {
            $loginForm.trigger('submit');
        }
    });

    // Enter event for signup modal
    $signupModal.on('keydown', function (e) {
        if ($signupModal.hasClass('show') && e.keyCode === 13) {
            $signupForm.trigger('submit');
        }
    });

    $('#login-form-email').on('keydown', function () {
        $(this).removeClass('is-invalid');
        $('.js-submit-login').attr('disabled', false);
    });

    $('#login-form-password').on('keydown', function () {
        $(this).removeClass('is-invalid');
        $('.js-submit-login').attr('disabled', false);
    });
};
