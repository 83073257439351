'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var focusHelper = require('base/components/focus');

var keyboardfocusableElements = 'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])';
var $menuLinksTopLevel = $('.navbar > .close-menu > .close-button > .close-btn, .navbar > .menu-group > .navbar-nav > .menu-item > .menu-link, .navbar > .menu-group > .navbar-nav .mobile-account-links > .menu-link');
var $firstTopMenuLink = $menuLinksTopLevel.first();
var $lastTopMenuLink = $menuLinksTopLevel.last();
var $menuLinksSecondary;
var $firstSecondaryMenuLink;
var $lastSecondaryMenuLink;

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    var $shownDropdown = $(element).closest('.dropdown.show');
    $shownDropdown.removeClass('show');
    $shownDropdown.children('.toggle-submenu').attr('tabindex', '-1');
    $shownDropdown.children('.dropdown-menu').find(keyboardfocusableElements).attr('tabindex', '-1');
    $(element).closest('li').detach();
};

var openMenu = function (eventElement) {
    $('.navbar-nav > li').each(function () {
        if (!$.contains(this, eventElement)) {
            $(this).find('.show').each(function () {
                clearSelection(this);
            });
            if ($(this).hasClass('show')) {
                $(this).removeClass('show');
                $(this).children('ul.dropdown-menu').removeClass('show');
            }
        }
    });
    // need to close all the dropdowns that are not direct parent of current dropdown
    $(eventElement).parent().addClass('show');
    $(eventElement).siblings('.dropdown-menu').addClass('show');
};

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                        .first()
                        .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus();
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('body').on('keydown', function (e) {
        if (e.which === 9 && $('.main-menu.in').length > 0 && $('.mobile-account-links:visible').length > 0) {
            if ($('.menu-item.show').length === 0) { // Is Top Level Menu
                if (e.target === $firstTopMenuLink[0] && e.keyCode === 9 && e.shiftKey) {
                    e.preventDefault();
                    $lastTopMenuLink.focus();
                    return;
                } else if (e.target === $lastTopMenuLink[0] && e.keyCode === 9 && !e.shiftKey) {
                    e.preventDefault();
                    $firstTopMenuLink.focus();
                    return;
                }
            } else if (e.target === $firstSecondaryMenuLink[0] && e.keyCode === 9 && e.shiftKey) { // Not Top Level Menu
                e.preventDefault();
                $lastSecondaryMenuLink.focus();
                return;
            } else if (e.target === $lastSecondaryMenuLink[0] && e.keyCode === 9 && !e.shiftKey) {
                e.preventDefault();
                $firstSecondaryMenuLink.focus();
                return;
            }
        }
    });

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                e.preventDefault();
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .addClass('mobile-cat-header');
                li.append(link);
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                var $parentEl = $(this).parent();
                $parentEl.children('.dropdown-menu')
                    .prepend(li)
                    .prepend(closeMenu);
                // copy navigation menu into view
                $parentEl.addClass('show');
                $menuLinksSecondary = $('.menu-item.show > .dropdown-menu').find('> .nav-menu [role="button"], > .top-category [role="button"], > .menu-row > .menu-column > .menu-list > .menu-item > [role="button"], > .menu-content-asset-wrapper .content-asset .shop-all-button .menu-link');
                $firstSecondaryMenuLink = $menuLinksSecondary.first();
                $lastSecondaryMenuLink = $menuLinksSecondary.last();
                $menuLinksSecondary.attr('tabindex', '0');
                $(link).focus();
            }
        })
        .on('mouseenter', function () {
            var delay = $('[data-menu-open-delay]').data('menuOpenDelay');
            var timer = null;

            if (isDesktop(this)) {
                var eventElement = this;
                if (!delay || delay === 0) {
                    openMenu(eventElement);
                    $(eventElement).siblings('.toggle-submenu').attr('aria-expanded', true);
                } else {
                    $(eventElement).on('mouseleave.timer', function () {
                        $(eventElement).off('mouseleave.timer');
                        clearTimeout(timer);
                    });

                    timer = setTimeout(function () {
                        openMenu(eventElement);
                        $(eventElement).siblings('.toggle-submenu').attr('aria-expanded', true);
                    }, delay);
                }
            }
        })
        .parent()
        .on('mouseleave', function () {
            if (isDesktop(this)) {
                $(this).removeClass('show');
                $(this).children('.dropdown-menu').removeClass('show');
                $(this).children('.toggle-submenu').attr('aria-expanded', false);
            }
        });

    var closeMenu = function () {
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();
        $('.navbar-toggler').focus();
        $('html').removeClass('html-menu-backer');
    };

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();

        closeMenu();
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');

        closeMenu();
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('.main-menu').toggleClass('in');
        $('.modal-background').show();
        $('.popover').removeClass('show');
        $('.main-menu').removeClass('d-none');
        $('.navbar-nav > .menu-item.dropdown > .toggle-submenu, .navbar-nav .mobile-account-links *:not(.menu-link)').attr('tabindex', '-1');
        $('.navbar-nav > .menu-item.dropdown > .dropdown-menu').find(keyboardfocusableElements).attr('tabindex', '-1');

        if ($('.navbar>.close-menu>.close-button>a').is(':visible')) {
            $('.navbar>.close-menu>.close-button>a').trigger('focus');
        }
        $('html').addClass('html-menu-backer');
    });

    keyboardAccessibility('.navbar-header .user',
        {
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.navbar-header .user').trigger('focus');
            },
            9: function ($popover) { // tab
                if ($popover.children('a').last().is(':focus')) {
                    event.preventDefault();
                    $popover.children('a.close').focus();
                }
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('body').on('click tap touchstart mouseover', '.navbar-header .user > a', function () {
        $(this).attr('aria-expanded', 'true');
        $('.popover').removeClass('show');
        $('.navbar-header .user .popover').toggleClass('show');
    });

    $('body').on('mouseleave', '.user', function () {
        $('#myaccount').attr('aria-expanded', 'false');
        $('.navbar-header .user .popover').removeClass('show');
    });

    $('body').on('click', '.navbar-header .user .popover .close', function (e) {
        e.preventDefault();
        $('#myaccount').attr('aria-expanded', 'false');
        $('.navbar-header .user .popover').removeClass('show');
        $('.navbar-header .user').focus();
    });

    keyboardAccessibility('.toggle-submenu',
        {
            32: function ($menuitem) { // spacebar
                $menuitem.trigger('mouseenter');
            }
        },
        function () {
            return $(this).prev('[data-toggle="dropdown"]');
        }
    );

    keyboardAccessibility('.close-submenu',
        {
            32: function ($menuitem) { // spacebar
                $menuitem.trigger('mouseleave');
            }
        },
        function () {
            return $(this).closest('.dropdown-menu').prevUntil('[data-toggle="dropdown"]');
        }
    );

    $('.close-submenu').on('click', function () {
        $(this).closest('.dropdown-menu').prevUntil('[data-toggle="dropdown"]').trigger('mouseleave');
    });

    $('body').on('keydown', '.navbar-nav > .dropdown.show', function (e) {
        if (!isDesktop(this)) {
            return;
        }

        if (e.keyCode === 27) {
            $(this).removeClass('show');
            $(this).children('.dropdown-menu').removeClass('show');
            $(this).children('.toggle-submenu').attr('aria-expanded', false);
        }

        var focusParams = {
            event: e,
            containerSelector: '.navbar-nav > .dropdown.show',
            firstElementSelector: '.shop-all-button .menu-link',
            lastElementSelector: '.dropdown-item:last > [role="button"]',
            nextToLastElementSelector: '.dropdown-item:last > [role="button"]'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('keydown', '.navbar-nav > .dropdown > .toggle-submenu', function (e) {
        if (e.keyCode === 13) {
            openMenu(this);
            $(this).attr('aria-expanded', true);
        }
    });
};
