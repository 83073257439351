'use strict';

var cartUtils = {
    updateCartTotals: function (data) {
        cartUtils.displayFreeShipping(data);
        $('.number-of-items').empty().append(data.resources.numberOfItems);
        var prices = data.totals.shippingsPrices;

        // show
        var s = function (a, b) {
            a.parents('.row').first().removeClass('d-none');
            a.html(b);
        };
        // hide
        var h = function (a) {
            a.parents('.row').first().addClass('d-none');
        };

        if (prices.home) {
            s($('.shipping-home-cost'), prices.home.formatted);
        } else {
            h($('.shipping-home-cost'));
        }

        if (prices.shipToStore) {
            s($('.shipping-store-cost'), prices.shipToStore.formatted);
        } else {
            h($('.shipping-store-cost'));
        }

        if (prices.delivery) {
            s($('.shipping-delivery-cost'), prices.delivery.formatted);
        } else {
            h($('.shipping-delivery-cost'));
        }

        if (prices.instore) {
            s($('.shipping-instore-cost'), prices.instore.formatted);
        } else {
            h($('.shipping-instore-cost'));
        }

        $('.tax-total').empty().append(data.totals.totalTax);
        $('.grand-total').empty().append(data.totals.grandTotal);
        $('.sub-total').empty().append(data.totals.subTotal);
        $('.subtotal-cost').empty().append(data.totals.subTotal);
        $('#carttotal').attr('value', data.totals.grandTotal);
        /* Affirm block for refreshing promo message */
        var totalCalculated = data.totals.grandTotal.substr(1).replace(/,/g, '');
        $('.affirm-as-low-as').attr('data-amount', (totalCalculated * 100).toFixed());
        var isWithinAffirmLimit = false;
        if ('affirmLimits' in window) {
            isWithinAffirmLimit = (parseFloat(totalCalculated) >= parseFloat(window.affirmLimits.min) && parseFloat(totalCalculated) <= parseFloat(window.affirmLimits.max));
        }
        if (isWithinAffirmLimit) {
            $('#js-affirm-checkout-now').show();
        } else {
            $('#js-affirm-checkout-now').hide();
            $('.affirm-as-low-as').attr('data-amount', NaN);
        }
        if ('affirm' in window && window.affirm.ui && typeof window.affirm.ui.refresh === 'function') {
            window.affirm.ui.refresh();
        }
        this.updateMinicartQuantity(data.numItems);
        $('.minicart-link').attr({
            'aria-label': data.resources.minicartCountOfItems,
            title: data.resources.minicartCountOfItems
        });
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.order-discount').removeClass('hide-order-discount');
            $('.order-discount-total').empty()
                .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
        } else {
            $('.order-discount').addClass('hide-order-discount');
        }

        $('.discounts').empty().append(data.totals.appliedCouponsHtml);

        if (data.totals.shippingLevelDiscountTotal.value > 0) {
            $('.shipping-discount').removeClass('hide-shipping-discount');
            $('.shipping-discount-total').empty().append('- ' +
                data.totals.shippingLevelDiscountTotal.formatted);
        } else {
            $('.shipping-discount').addClass('hide-shipping-discount');
        }

        var items = [];
        if (data.items) {
            if (data.items.items) {
                items = data.items.items;
            } else {
                items = data.items;
            }
        }
        items.forEach(function (item) {
            if (data.totals.orderLevelDiscountTotal.value > 0) {
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
            }
            if (item.renderedPromotions) {
                $('.item-' + item.UUID).empty().append(item.renderedPromotions);
            } else {
                $('.item-' + item.UUID).empty();
            }
            $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
            $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
            $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        });
    },

    updateApproachingDiscounts: function (approachingDiscounts) {
        var html = '';
        const $container = $('.js-coupons-and-promos-top');
        $container.empty();

        if (approachingDiscounts.length > 0) {
            approachingDiscounts.forEach(function (item) {
                html += '<div class="approaching-description text-center">'
                    + item.discountMsg + '</div>';
            });
            $container.removeClass('d-none');
        } else {
            $container.addClass('d-none');
        }

        $container.append(html);
    },

    validateBasket: function (data) {
        if (data.hkUnavailable) return;

        if (data.valid.error) {
            if (data.valid.message) {
                var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                    'fade show" role="alert">' +
                    '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                    '<span aria-hidden="true">&times;</span>' +
                    '</button>' + data.valid.message + '</div>';

                $('.cart-error').append(errorHtml);
            } else {
                $('.cart').empty().append('<div class="row"> ' +
                    '<div class="col-12 text-center"> ' +
                    '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                    '</div> ' +
                    '</div>'
                );
                $('.number-of-items').empty().append(data.resources.numberOfItems);
                this.updateMinicartQuantity(data.numItems);
                $('.minicart-link').attr({
                    'aria-label': data.resources.minicartCountOfItems,
                    title: data.resources.minicartCountOfItems
                });
                $('.minicart .popover').empty();
                $('.minicart .popover').removeClass('show');
            }

            $('.checkout-btn').addClass('disabled');
            $('.js-express-checkout-btns').addClass('d-none');
        } else {
            $('.checkout-btn').removeClass('disabled');
            $('.js-express-checkout-btns').removeClass('d-none');
        }

        $('.product-info .error-message').addClass('d-none');
        var items = [];
        if (data.items) {
            if (data.items.items) {
                items = data.items.items;
            } else {
                items = data.items;
            }
        }
        items.forEach(function (item) {
            if (item.quantityOptions.quantityOverloaded) {
                $('.product-info.uuid-' + item.UUID + ' .error-message').removeClass('d-none');
            }
        });
    },

    displayFreeShipping: function (data) {
        var $freeShippingContainer = $('.free-shipment-notifications');

        if ($freeShippingContainer.is('.free-shipment-notifications-total')) {
            $freeShippingContainer.toggleClass('d-none', !data.freeShippingHtml);
        }
        $freeShippingContainer.html(data.freeShippingHtml);
    },

    handleNewShipMethod: function (container, shipType, shipMethod, data) {
        if (container.length > 0) {
            var pliUUID = container.data('uuid');
            var req = {
                uuid: pliUUID
            };
            var methodData = {
                storeID: data.storeID,
                shipType: shipType,
                shipMethod: shipMethod,
                uuid: pliUUID
            };
            container.spinner().start();
            $.get(container.data('set-method'), methodData).done(function (editData) {
                $('.uuid-' + editData.uuid + ' .product-edit .standalone-link-small[title="edit"]').attr('href', editData.editUrl);
                $.get(container.data('url'), req).done(function (resp) {
                    container.replaceWith(resp);

                    $('.info-icon').on('mouseenter focusin', function () {
                        $(this).find('.tooltip').removeClass('d-none');
                    });

                    $('.info-icon').on('mouseleave focusout', function () {
                        $(this).find('.tooltip').addClass('d-none');
                    });

                    $('#inStoreInventoryModal .js-store-locator__store').removeClass('selected-store');
                    $('#inStoreInventoryModal .js-store-locator__store[data-store-id=' + data.storeID + ']').addClass('selected-store');
                    $.get(container.data('get-cart-model')).done(function (cartData) {
                        $('.coupons-and-promos').empty().append(cartData.totals.discountsHtml);
                        cartUtils.updateCartTotals(cartData);
                        cartUtils.updateApproachingDiscounts(cartData.approachingDiscounts);
                        cartUtils.validateBasket(cartData);
                        $('body').trigger('cart:update', cartData);
                        container.spinner().stop();
                        var tooltipOptions = {
                            offset: '0, 10px'
                        };
                        $('[data-toggle="tooltip"').tooltip(tooltipOptions);
                    }).fail(function () {
                        container.spinner().stop();
                    });
                }).fail(function () {
                    container.spinner().stop();
                }).always(function () {
                    setTimeout(function () {
                        $('#inStoreInventoryModal').modal('hide');
                    }, 500);
                });
            }).fail(function () {
                container.spinner().stop();
            });
        }
    },

    updateMinicartQuantity: function (amount) {
        $('.minicart-quantity').empty().append(amount);
        var minicartCount = parseInt($('.minicart .minicart-quantity').text(), 10);
        if (minicartCount && minicartCount > 0) {
            $('.minicart .minicart-quantity').removeClass('d-none');
        } else {
            $('.minicart .minicart-quantity').addClass('d-none');
        }
    }

};

module.exports = cartUtils;
