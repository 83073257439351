'use strict';

var prefStore = {
    priv: {
        handleSet: function (elem, pageType) {
            var container = $('.header-select-store');
            if (container.length > 0) {
                var button = elem;
                var $iconEl = elem.closest('.store-detail');
                var url = button.data('url');
                $.get(url, { pageType: pageType }, function (data) {
                    if (data && data.success) {
                        if (data.needPageReload) {
                            window.location.href = window.location.href;
                            return;
                        }

                        var $preferedStoreLabel = $('.prefered-store-label').remove();

                        $('#inStoreInventoryModal .js-store-locator__store').removeClass('selected-store');
                        $('#inStoreInventoryModal .js-store-locator__store[data-store-id=' + data.storeID + ']')
                            .addClass('selected-store').find('.store-locator__store__details').prepend($preferedStoreLabel);
                        $('.selected-store-item').addClass('d-none');
                        $('.make-my-store').removeClass('d-none');
                        button.addClass('d-none');
                        $iconEl.find('.selected-store-item').removeClass('d-none');

                        // update header with new store
                        var headIncludeUrl = container.data('url');
                        $.get(headIncludeUrl, function (storeHtml) {
                            container.html(storeHtml);
                            if (pageType === 'search') {
                                setTimeout(function () {
                                    $('#inStoreInventoryModal').modal('hide');
                                }, 500);
                                var refines = $('.refinement-bar__wrapper');
                                if (refines.length > 0) {
                                    $.get(refines.data('url'), function (refineData) {
                                        refines.replaceWith(refineData);
                                    });
                                } else {
                                    window.location.href = window.location.href;
                                }
                            }
                        });
                    }
                });
            }
        }
    },
    set: function () {
        $('body').on('click', '.make-my-store', function () {
            prefStore.priv.handleSet($(this), 'search');
        });
        $('body').on('click', '.select-store-search', function () {
            prefStore.priv.handleSet($(this), 'search');
        });
    },

    globalEvents: function () {
        $('body').on('click', '.header-select-store .popover .close', function () {
            $('.header-select-store .popover').removeClass('show');
            $('.header-select-store').removeClass('open');
            $('.header-select-store .my-store-header').attr('aria-expanded', 'false');
        });

        $('body').on('mouseover', '.header-select-store .my-store-header', function () {
            $('.my-store-header').attr('aria-expanded', 'true');
            $('.popover').removeClass('show');
            $('.header-select-store .popover').toggleClass('show');
        });

        $('body').on('mouseleave', '.header-select-store', function (e) {
            e.preventDefault();
            $('.my-store-header').attr('aria-expanded', 'false');
            $('.header-select-store .popover').removeClass('show');
        });

        $('body').on('click tap touchstart', '.header-select-store .my-store-header', function () {
            if ($('.my-store-header').attr('aria-expanded') === 'true') {
                $('.my-store-header').attr('aria-expanded', 'false');
            } else {
                $('.my-store-header').attr('aria-expanded', 'true');
            }
            $('.popover:not(.store-popover)').removeClass('show');
            $('.header-select-store').toggleClass('open');
            $('.header-select-store .popover').toggleClass('show');
        });
    }
};

module.exports = prefStore;
