/* eslint-disable no-empty */
'use strict';

var cart = require('../cart/cart');
var cartUtils = require('../cart/utils');
var wishlistCart = require('wishlists/components/miniCart');
var keyboardAccessibility = require('base/components/keyboardAccessibility');
var focusHelper = require('base/components/focus');
var hkAvailability = require('../checkout/hkAvailability');
var hkUtils = require('../utils/hkutils');

var updateMiniCart = true;

/**
 * Send Focus to Close button after minicart open (ADA)
 */
function giveFocus() {
    $('body').on('keydown', '.minicart-link', function (e) {
        if (e.keyCode === 9) {
            $(this).blur();
            $('.minicart .close').focus();
        }
    });
}

/**
 * Trap Focus on Shopping Cart
 */
function trapFocus() {
    $('body').on('keydown', '#shoppingCartModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#shoppingCartModal',
            firstElementSelector: '.close',
            lastElementSelector: '.gpay-button',
            nextToLastElementSelector: '.checkout-cart'
        };
        focusHelper.setTabNextFocus(focusParams);
    });
}

/**
 * Opens mini bag modal
 */
function openMiniBagModal() {
    $('.minicart-screen-cover').addClass('onscreen');
    $('.minicart-modal-anchor').addClass('show');
    $('.minicart-modal-anchor').removeClass('hover-show');
    $('body').addClass('noscroll');
    $('.toggle-minicart').attr('aria-expanded', 'true');
}

/**
 * Close mini bag modal
 */
function closeMiniBagModal() {
    $('.minicart-modal-anchor').removeClass('show');
    $('.minicart-screen-cover').removeClass('onscreen');
    $('.minicart-modal-anchor').removeClass('hover-hide');
    $('body').removeClass('noscroll');
    $('.toggle-minicart').attr('aria-expanded', 'false');
}

/**
 * Counts loaded express options to apply grid columns
 */
function spaceExpressOptions() {
    var $paypal = $('.paypal-button-container-mini');
    var $gpay = $('#js-googlepay-minicart-container');
    var $applePay = $('.apple-pay-cart');

    var count = 0;
    if ($paypal.length > 0 && $paypal.html().length > 0) {
        count += 1;
    }
    if ($gpay.length > 0 && $gpay.html().length > 0) {
        count += 1;
    }
    if ($applePay.length > 0 && $applePay.html().length > 0) {
        count += 1;
    }
    var countClass = 'count-' + count;
    $('.minicart-modal-anchor .js-express-checkout-btns').addClass(countClass);
}

/**
 * Function to wait for an element and to detect it.
 * @param {string} selector - The CSS selector of the element to wait for.
 * @returns {Promise<Element>} A promise that resolves with the detected element.
 */
function waitForElement(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer1 = new MutationObserver(() => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer1.disconnect();
            }
        });

        observer1.observe(document.body, {
            childList: true,
            subtree: true
        });

        // Ensure the function always returns a value
        return observer1;
    });
}

/**
 * Updates the class of the express checkout buttons based on their current count class.
 * @param {Element} expressCheckoutBtns - The express checkout buttons element.
 */
function updateExpressCheckoutBtns(expressCheckoutBtns) {
    if (expressCheckoutBtns) {
        if (expressCheckoutBtns.classList.contains('count-1')) {
            expressCheckoutBtns.classList.remove('count-1');
            expressCheckoutBtns.classList.add('count-2');
        } else if (expressCheckoutBtns.classList.contains('count-2')) {
            expressCheckoutBtns.classList.remove('count-2');
            expressCheckoutBtns.classList.add('count-3');
        } else if (expressCheckoutBtns.classList.contains('count-3')) {
            expressCheckoutBtns.classList.remove('count-3');
            expressCheckoutBtns.classList.add('count-4');
        }
    }
}

// Updates count- class when Apple pay button is loaded
waitForElement('.dw-apple-pay-button').then(() => {
    var expressCheckoutBtns = document.querySelector('.js-express-checkout-btns');
    updateExpressCheckoutBtns(expressCheckoutBtns);
});

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            cartUtils.updateMinicartQuantity(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('tangiblee:addtocart', function (event, count) {
        $('.minicart').trigger('count:update', count);
        updateMiniCart = true;
    });

    $('body').on('click', '.modal-title .close-button', function () {
        closeMiniBagModal();
    });

    $('.minicart').on('mouseleave', function () {
        $('.minicart-modal-anchor').removeClass('hover-show');
    });

    $('.minicart').on('mouseenter keydown', function (e) {
        if (e.type === 'keydown' && e.keyCode !== 13) {
            return;
        }

        if ($('.search:visible').length === 0) {
            return;
        }
        var url = $('.minicart').data('action-url');
        var $miniCartAnchor = $('.minicart-modal-anchor');
        if (!updateMiniCart) {
            hkAvailability.minicart();
            $miniCartAnchor.addClass('hover-show');
            setTimeout(function () {
                if ($miniCartAnchor.hasClass('hover-show')) {
                    openMiniBagModal();
                }
            }, 1500);
            $('.toggle-minicart').attr('aria-expanded', 'true');
            hkUtils.scrollToHkUnavailable('minicart');
            $('body').trigger('minicart:shown');
            return;
        }

        $miniCartAnchor.addClass('hover-show');
        setTimeout(function () {
            if ($miniCartAnchor.hasClass('hover-show')) {
                openMiniBagModal();
            }
        }, 1500);

        $('.toggle-minicart').attr('aria-expanded', 'true');
        $('.minicart-modal-anchor').spinner().start();
        $.get(url, function (data) {
            $('.minicart-modal-anchor').empty();
            $('.minicart-modal-anchor').append(data);
            updateMiniCart = false;

            var isPaypalEnabled = !!($('#paypal_enabled').length > 0 && document.getElementById('paypal_enabled').value === 'true');
            var isGooglePayEnabled = !!($('#isGooglePayEnabled').length > 0 && $('#isGooglePayEnabled').val() === 'true');

            if (isPaypalEnabled) {
                // eslint-disable-next-line
                paypalhelper.paypalMini();
            }
            if (isGooglePayEnabled) {
                // eslint-disable-next-line
                onGooglePayLoaded();
            }

            $('.minicart-link').blur();
            $('.minicart .close').focus();

            if ($('#shoppingCartModal .js-product-line-item[data-is-ship-to-store="true"]').length > 0) {
                $('.apple-pay-button-label-container').hide();
            }

            spaceExpressOptions();

            // Call waitForElement after spaceExpressOptions
            waitForElement('.dw-apple-pay-button').then(function () {
                var expressCheckoutBtns = document.querySelector('.js-express-checkout-btns');
                updateExpressCheckoutBtns(expressCheckoutBtns);
            });

            $.spinner().stop();
            hkUtils.scrollToHkUnavailable('minicart');

            $('body').trigger('minicart:shown');
        });
        event.stopImmediatePropagation();
    });

    $('.minicart-link').on('keydown', function (e) {
        if (e.type === 'keydown' && e.keyCode === 13) {
            e.preventDefault();
        }
    });

    giveFocus();
    trapFocus();

    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
            $('.toggle-minicart').attr('aria-expanded', 'false');
        }
    });

    $('.minicart').on('mouseleave focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        if (!($(document).find('.paypal-checkout-sandbox').length > 0)) {
            closeMiniBagModal();
        }
        $('.toggle-minicart').attr('aria-expanded', 'false');

        event.stopImmediatePropagation();
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            // eslint-disable-next-line
            location.reload();
        }
    });
    $('body').on('mouseenter focusin', '.minicart-modal-anchor', function () {
        $(this).removeClass('hover-hide');
    });
    $('body').on('mouseleave focusout', '.minicart-modal-anchor', function () {
        $('.minicart-modal-anchor').addClass('hover-hide');
        setTimeout(function () {
            if ($('.minicart-modal-anchor').hasClass('hover-hide')) {
                closeMiniBagModal();
            }
        }, 500);
    });
    $('body').on('click', '.minicart .popover .close', function () {
        $('.minicart .popover').removeClass('show');
        $('.toggle-minicart').attr('aria-expanded', 'false');
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
    $('body').on('keydown', '.minicart .close', function (e) {
        if (e.keyCode === 13) {
            $('.minicart-link').focus();
            $('.minicart .popover').removeClass('show');
            $('.toggle-minicart').attr('aria-expanded', 'false');
        }
    });

    wishlistCart.moveToWishlist();

    keyboardAccessibility('.toggle-minicart',
        {
            32: function ($minicart) { // spacebar
                $minicart.trigger('focusin');
                $minicart.trigger('mouseenter');
            }
        },
        function () {
            return $(this).closest('.minicart');
        }
    );
};
