'use strict';

const SELECTORS = {
    gifs: '.gif-player',
    gifSource: '.gif-source',
    gifEl: '.gif-video',
    gifSrc: 'data-gif',
    gifStaticSrc: 'data-img',
    playButton: '.gif-play',
    pauseButton: '.gif-pause'
};

/**
 * Toggles the visibility of play and pause buttons based on the gif's current state.
 *
 * @param {string} action - The action to perform on the gif element, either 'play' or 'pause'.
 * @param {HTMLImageElement} gif - The gif element to check the state of.
 * @param {HTMLElement} playBtn - The play button element to toggle visibility of.
 * @param {HTMLElement} pauseBtn - The pause button element to toggle visibility of.
 */
function toggleButtonVisibility(action, gif, playBtn, pauseBtn) {
    if (action === 'play') {
        playBtn.classList.add('d-none');
        pauseBtn.classList.remove('d-none');
    } else {
        playBtn.classList.remove('d-none');
        pauseBtn.classList.add('d-none');
    }
}

/**
 * Toggles the state of a gif element between play and pause.
 *
 * @param {Object} parameters - The action to perform on the gif element, either 'play' or 'pause'.
 * @param {string} action - The action to perform on the gif element, either 'play' or 'pause'.
 * @param {HTMLImageElement} gifContainer - The gif element to toggle.
 * @param {HTMLElement} playBtn - The play button element associated with the gif element.
 * @param {HTMLElement} pauseBtn - The pause button element associated with the gif element.
 */
function toggleVideoState({ event, action, gifContainer, playButton, pauseButton }) {
    if (event) event.preventDefault();
    const isPlay = action === 'play';
    const gifElement = gifContainer.querySelector(SELECTORS.gifEl);
    const gifDesktop = gifContainer.querySelector(SELECTORS.gifSource);
    if (gifElement) {
        gifElement.src = encodeURI(gifElement.getAttribute(isPlay ? SELECTORS.gifSrc : SELECTORS.gifStaticSrc));
    }
    if (gifDesktop) {
        gifDesktop.srcset = encodeURI(gifDesktop.getAttribute(isPlay ? SELECTORS.gifSrc : SELECTORS.gifStaticSrc));
    }
    toggleButtonVisibility(action, gifElement, playButton, pauseButton);
}

/**
 * Attaches event listeners to gif elements for play, pause, and mute functionality.
 */
function inifGif() {
    const gifSelector = document.querySelectorAll(SELECTORS.gifs);

    for (let i = 0; i < gifSelector.length; i++) {
        const gifContainer = gifSelector[i];
        const playButton = gifContainer.querySelector(SELECTORS.playButton);
        const pauseButton = gifContainer.querySelector(SELECTORS.pauseButton);

        if (playButton) {
            playButton.addEventListener('click', (event) =>
                toggleVideoState({
                    event,
                    action: 'play',
                    gifContainer,
                    playButton,
                    pauseButton
                })
            );
        }
        if (pauseButton) {
            pauseButton.addEventListener('click', (event) =>
                toggleVideoState({
                    event,
                    action: 'pause',
                    gifContainer,
                    playButton,
                    pauseButton
                })
            );
        }
        if (gifContainer) {
            gifContainer.addEventListener('ended', (event) =>
                toggleVideoState({
                    event,
                    action: 'pause',
                    gifContainer,
                    playButton,
                    pauseButton
                })
            );
        }
    }
}

module.exports = {
    init: inifGif
};
